import { intersectionWith } from 'lodash-es'
import {
  EGtmEvent,
  EJobApplicationStatus,
  EPeriods,
  EPipelineStage,
  EScreeningQuestionType
} from '@/helpers/enums'

export function createQuestionLabel(question, ctx) {
  const rawLabel = question.questionLabel || question.label

  if (!rawLabel) return

  const label = ctx.$t(rawLabel)
  const suffix = question.mandatory ? '<span class="red--text">*</span>' : ''

  return `${label}${suffix}`
}

export async function updateQuestionValue(ctx, value, objectValue) {
  let responseCode

  try {
    ctx.isLoading = true

    const applicationId = ctx.$route.params.appId
    const query = {
      qid: ctx.question.id,
      component: ctx.question.componentName
    }
    const { type } = ctx.question.questionJson
    const payload = {
      groupId: ctx.question.answers?.[0]?.groupId ?? 0,
      type,
      value,
      objectValue
    }

    if (!objectValue) {
      try {
        payload.objectValue = JSON.parse(value)
      } catch (ignore) {}
    }

    const response = await ctx.$api.question.update(applicationId, [payload], query)
    responseCode = response.status

    // prettier-ignore
    ctx.originalValue = ctx.question.questionJson.type === EScreeningQuestionType.CHECKBOX_LIST
      ? ctx.values
      : value
    ctx.error = null

    ctx.$emit('updated')

    await Promise.all(
      ctx.$store.dispatch('application/fetchQuestions', applicationId),
      ctx.$store.dispatch('application/fetchHistory', applicationId)
    )
  } catch (err) {
    responseCode = err.response?.status
    ctx.error = ctx.$t('msp.v2.message.generic.error')
  } finally {
    ctx.isLoading = false
    ctx.toggleEditing()

    const applicationData = ctx.$store.getters['application/application']
    await ctx.$pushEvent(EGtmEvent.HR_EDIT_APPLICATION_QUESTIONS, {
      responseCode,
      jobHandleId: applicationData.jobHandle,
      talentId: applicationData.freelancer.id
    })
  }
}

export const RateUpdateableApplicationStatuses = [
  EJobApplicationStatus.ACTIVE,
  EJobApplicationStatus.SEND_FAILED
]

export const historyEntryKeyToEperiod = (key) => {
  switch (key) {
    case 'hourlyRate':
      return EPeriods.HR
    case 'dailyRate':
      return EPeriods.DAY
    case 'weeklyRate':
      return EPeriods.WEEK
    case 'monthlyRate':
      return EPeriods.MONTH
    case 'yearlyRate':
      return EPeriods.YEAR
    case 'projectRate':
      return EPeriods.PROJECT
    default:
      return EPeriods.HR
  }
}

export const makeStatusVerbiage = (ctx, status) => {
  switch (status) {
    case EJobApplicationStatus.ACTIVE:
      return ctx.$t('msp.v2.application.status.active')
    case EJobApplicationStatus.HIRED:
      return ctx.$t('msp.v2.application.status.hired')
    case EJobApplicationStatus.REJECTED_BY_MSP:
      return ctx.$t('msp.v2.application.status.rejectedbymsp')
    case EJobApplicationStatus.REJECTED_BY_VMS:
      return ctx.$t('msp.v2.application.status.rejectedbyvms')
    case EJobApplicationStatus.SEND_FAILED:
      return ctx.$t('msp.v2.application.status.sendfailed')
    case EJobApplicationStatus.SEND_PENDING:
      return ctx.$t('msp.v2.application.status.sendpending')
    case EJobApplicationStatus.SENT_TO_CLIENT:
      return ctx.$t('msp.v2.application.status.senttoclient')
    case EJobApplicationStatus.UNCONFIRMED:
      return ctx.$t('msp.v2.application.status.unconfirmed')
    case EJobApplicationStatus.WITHDRAWN:
      return ctx.$t('msp.v2.application.status.withdrawn')
    case EJobApplicationStatus.PLACEHOLDER:
      return ctx.$t('msp.v2.application.status.placeholder')
  }
}

export const customScreeningValidationMessages = (ctx) => ({
  required: ctx.$t('msp.v2.application.screening.field.required')
})

export const getPipelineStageByApplicationStatus = (status) => {
  switch (status) {
    case EJobApplicationStatus.ACTIVE:
    case EJobApplicationStatus.UNCONFIRMED:
      return EPipelineStage.ACTIVE
    // --
    case EJobApplicationStatus.HIRED:
      return EPipelineStage.HIRED
    // --
    case EJobApplicationStatus.SENT_TO_CLIENT:
    case EJobApplicationStatus.SEND_FAILED:
    case EJobApplicationStatus.SEND_PENDING:
      return EPipelineStage.SEND2VMS
    // --
    case EJobApplicationStatus.REJECTED_BY_MSP:
    case EJobApplicationStatus.WITHDRAWN:
      return EPipelineStage.REJECTED_WITHDRAW
    // --
    case EJobApplicationStatus.REJECTED_BY_VMS:
      return EPipelineStage.REJECTED
    // --
    default:
      return EPipelineStage.ACTIVE
  }
}

export function compareValues(value, savedAnswer) {
  try {
    return (
      value === savedAnswer.value ||
      value === savedAnswer.objectValue ||
      value === JSON.stringify(savedAnswer.objectValue)
    )
  } catch (err) {
    return false
  }
}

// TWAGOS-8812
// The option.selected doesn't work properly (so far)
// Also, with the new app flow we introduced question data, in which answers[0].value
// may be different from options[0].value (e.g. "0.0" and 0)
export function defineSelectedOptions(question) {
  const { options = [] } = question.questionJson ?? {}
  const { answers: userAnswers = [] } = question

  const selectedOptions = intersectionWith(options, userAnswers, ({ value }, answer) =>
    compareValues(value, answer)
  )

  return selectedOptions
}
