import { DEFAULT_CONTEXT } from '@/config/config'

export const state = () => ({
  clientContext: { ...DEFAULT_CONTEXT },
  clientConfig: [],
  leConfigs: new Map(),
  currentLocation: {
    country: null,
    city: null,
    latitude: null,
    longitude: null
  },
  calendarEnabled: false,
  countriesList: [],
  pageLoader: false,
  leftDrawer: false,
  rightDrawer: false,
  mobileMenu: false,
  getHistory: [],
  languages: [],
  tiers: [],
  leaveApp: {},
  leaveTalent: {},
  leavePool: {},
  leaveProject: {},
  leaveEmpty: false,
  countriesListFromApi: [],
  locale: '',
  messages: new Map(),
  appVersion: ''
})

export const actions = {
  async nuxtServerInit({ dispatch }) {
    try {
      await Promise.all([dispatch('pullCountries'), dispatch('currentLocation')])
    } catch (err) {}
  },

  async pullCountries({ commit }) {
    try {
      const { data } = await this.$api.utils.getCountry()
      const countries = []

      const currentLocale = this.$defineLocale(this.app.$i18n.locale)
      const translate = (item) => {
        const translations = Object.entries(item.translations)
        const lang = translations.find(([key]) => key === currentLocale)

        return lang?.[1] ?? item.name
      }

      for (const country of data) {
        if (country.phoneCode === '') continue

        countries.push({
          text: translate(country),
          value: country.iso2,
          callingCode: country.phoneCode
        })
      }

      const clientPref = this.$useConfig(this.$EClientConfig.PREFERRED_SORTED_COUNTRIES)
      const sortFn = clientPref?.length
        ? (a, b) => {
            const aIdx = clientPref.indexOf(a.value)
            const bIdx = clientPref.indexOf(b.value)

            if (~aIdx && ~bIdx) return aIdx - bIdx
            if (~aIdx) return -1
            if (~bIdx) return 1
            return a.text.localeCompare(b.text)
          }
        : (a, b) => a.text.localeCompare(b.text)

      countries.sort(sortFn)

      commit('COUNTRIES_LIST', countries)
    } catch (err) {
      console.error(err)
    }
  },

  async pullLanguages({ commit }) {
    const { data } = await this.$api.utils.getLanguages()
    commit('SET_LANGUAGES', data.languages)
  },

  async pullTiers({ commit }) {
    const { data } = await this.$api.client.getTier()
    commit('SET_TIERS', data)
  },

  async currentLocation({ commit }) {
    const { data: location } = await this.$axios.get('/napi/location')
    commit('CURRENT_LOCATION', location)
  }
}

export const mutations = {
  SET_CLIENT_CONTEXT: (state, payload) => {
    state.clientContext = payload
  },

  SET_CLIENT_CONFIG: (state, payload) => {
    state.clientConfig = payload
  },

  SET_LE_CONFIGS: (state, payload) => {
    state.leConfigs = payload
  },

  CURRENT_LOCATION: (state, payload) => {
    state.currentLocation = payload
  },

  SET_LANGUAGES: (state, payload) => {
    state.languages = payload
  },

  COUNTRIES_LIST: (state, payload) => {
    state.countriesList = payload
  },

  PAGE_LOADER: (state, payload) => {
    state.pageLoader = payload
  },

  TOGGLE_LEFT_DRAWER: (state, payload) => {
    state.leftDrawer = payload
  },

  TOGGLE_RIGHT_DRAWER: (state, payload) => {
    state.rightDrawer = payload
  },

  TOGGLE_MOBILE_MENU: (state, payload) => {
    state.mobileMenu = payload
  },

  GET_HISTORY: (state, payload) => {
    state.getHistory = payload
  },

  CALENDAR_ENABLED: (state, payload) => {
    state.calendarEnabled = payload
  },

  SET_TIERS: (state, tiers) => {
    state.tiers = tiers
  },

  LEAVE_APP: (state, payload) => {
    state.leaveApp = payload
  },

  LEAVE_TALENT: (state, paylod) => {
    state.leaveTalent = paylod
  },

  LEAVE_POOL: (state, paylod) => {
    state.leavePool = paylod
  },

  LEAVE_PROJECT: (state, paylod) => {
    state.leaveProject = paylod
  },

  LEAVE_EMPTY: (state, paylod) => {
    state.leaveEmpty = paylod
  },

  SET_LOCALE: (state, payload) => {
    state.locale = payload
  },

  SET_MESSAGES: (state, messages) => {
    state.messages = new Map(Object.entries(messages))
  },

  SET_APP_VERSION: (state, payload) => {
    state.appVersion = payload
  }
}

export const getters = {
  countriesList: ({ countriesList }) => countriesList,
  pageLoader: ({ pageLoader }) => pageLoader,
  leftDrawer: ({ leftDrawer }) => leftDrawer,
  rightDrawer: ({ rightDrawer }) => rightDrawer,
  mobileMenu: ({ mobileMenu }) => mobileMenu,
  getHistory: ({ getHistory }) => getHistory,
  calendarEnabled: ({ calendarEnabled }) => calendarEnabled,
  currentLocation: ({ currentLocation }) => currentLocation,
  languages: ({ languages }) => languages,

  getLanguage:
    ({ languages }) =>
    (code) =>
      languages.find((l) => l.iso === code),

  tiers: ({ tiers }) => tiers,
  leaveApp: ({ leaveApp }) => leaveApp,
  leaveTalent: ({ leaveTalent }) => leaveTalent,
  leavePool: ({ leavePool }) => leavePool,
  leaveProject: ({ leaveProject }) => leaveProject
}
