
import { EScreeningQuestionType } from '@/helpers/enums'
import { compareValues } from '@/helpers/application'

export default {
  name: 'AppQuestionElement',

  components: {
    AppQuestionNumber: () => import('@/components/App/QuestionNumber'),
    AppQuestionDate: () => import('@/components/App/QuestionDate'),
    AppQuestionDropdown: () => import('@/components/App/QuestionDropdown'),
    AppQuestionRadio: () => import('@/components/App/QuestionRadio'),
    AppQuestionTextarea: () => import('@/components/App/QuestionTextarea'),
    AppQuestionText: () => import('@/components/App/QuestionText'),
    AppQuestionCheckbox: () => import('@/components/App/QuestionCheckbox')
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    component() {
      switch (this.question.questionJson.type) {
        case EScreeningQuestionType.CURRENCY:
        case EScreeningQuestionType.NUMBER:
          return 'AppQuestionNumber'
        case EScreeningQuestionType.DATE:
          return 'AppQuestionDate'
        case EScreeningQuestionType.DROPDOWN:
          return 'AppQuestionDropdown'
        case EScreeningQuestionType.RADIO:
          return 'AppQuestionRadio'
        case EScreeningQuestionType.TEXTAREA:
          return 'AppQuestionTextarea'
        case EScreeningQuestionType.TEXT:
          return 'AppQuestionText'
        case EScreeningQuestionType.CHECKBOX:
          return 'AppQuestionCheckbox'
        case EScreeningQuestionType.CHECKBOX_LIST:
          return 'AppQuestionCheckboxList'
        default:
          throw new Error('Invalid question component')
      }
    },

    visibleChildren() {
      return this.question.children?.length
        ? this.question.children.filter(({ parentQuestionAnswer }) =>
            compareValues(parentQuestionAnswer, this.question.answers?.[0])
          )
        : []
    }
  }
}
