import { EFlowType, EPermission, EProjectStatus } from '@/helpers/enums'

export function projectMenuList(obj) {
  const status = obj?.status || EProjectStatus.OPEN
  const disabled = obj?.disabled || []
  const display = obj.ctx.$store.state.search.projectMenu || []
  const disableInvitation = ![EProjectStatus.OPEN, EProjectStatus.PARTLY_FILLED].includes(status)
  const idSuffix = obj.ctx.source?.id ?? 'bulk'

  return [
    {
      id: `addToPool_${idSuffix}`,
      event: EFlowType.ADD_TO_POOL,
      icon: '$vuetify.icons.addPool',
      label: obj.ctx.$t('msp.v2.link.add.to.pool'),
      disabled: disabled.includes(EFlowType.ADD_TO_POOL),
      display: display.includes(EFlowType.ADD_TO_POOL)
    },
    {
      id: `inviteTalent_${idSuffix}`,
      event: EFlowType.INVITE_TALENT,
      icon: '$vuetify.icons.addTalent',
      label: obj.ctx.$t('msp.v2.link.invite.talent'),
      disabled: disabled.includes(EFlowType.INVITE_TALENT) || disableInvitation,
      display: display.includes(EFlowType.INVITE_TALENT)
    },
    {
      id: `allocateMsp_${idSuffix}`,
      event: EFlowType.ALLOCATE_MSP,
      icon: '$vuetify.icons.assignMsp',
      label: obj.ctx.$t('msp.v2.link.allocate.msp'),
      disabled: disabled.includes(EFlowType.ALLOCATE_MSP),
      display:
        obj.ctx.$useConfig(obj.ctx.$EClientConfig.ENABLE_TEAM) &&
        display.includes(EFlowType.ALLOCATE_MSP)
    },
    {
      id: `projectStatus_${idSuffix}`,
      event: EFlowType.CHANGE_PROJECT_STATUS,
      icon: '$vuetify.icons.changeStatus',
      label: obj.ctx.$t('msp.v2.project.status.change'),
      disabled: disabled.includes(EFlowType.CHANGE_PROJECT_STATUS),
      display: display.includes(EFlowType.CHANGE_PROJECT_STATUS)
    },
    {
      id: `projectDelete_${idSuffix}`,
      event: EFlowType.PROJECT_DELETE,
      icon: '$vuetify.icons.delete',
      label: obj.ctx.$t('msp.v2.link.delete'),
      disabled: disabled.includes(EFlowType.PROJECT_DELETE),
      display:
        display.includes(EFlowType.PROJECT_DELETE) &&
        obj.ctx.$checkPermission(EPermission.JOB_MANAGE_DELETE)
    }
  ]
}

export const projectManagementMenu = (ctx) => [
  {
    id: 'projectManagementLink',
    to: '#projectManagement',
    name: ctx.$t('msp.v2.project.job.posting'),
    display: true
  },
  {
    id: 'projectTimelineLink',
    to: '#projectTimeline',
    name: ctx.$t('msp.v2.project.manage.timeline.heading'),
    display: true
  },
  {
    id: 'projectPositionsLink',
    to: '#projectPositions',
    name: ctx.$t('msp.v2.project.manage.positions.heading'),
    display: ctx.positionsVisible
  },
  {
    id: 'projectPoolsLink',
    to: '#projectPools',
    name: ctx.$t('msp.v2.project.manage.talent.pools.publishing'),
    display: ctx.poolVisible
  },
  {
    id: 'projectTeamLink',
    to: '#projectTeam',
    name: ctx.$t('msp.v2.project.team'),
    display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_TEAM)
  },
  {
    id: 'projectQuestionsLink',
    to: '#projectQuestions',
    name: ctx.$t('msp.v2.project.questions'),
    display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_PAQ)
  }
]
