import { isNil, omit } from 'lodash-es'
import { EFilter, EHistoryPage, EPipelineStage } from '@/helpers/enums'

export default ({ app, store, redirect }, inject) => {
  // query string helpers
  inject('querySelectionArray', (list) => (list ? list.split(',')?.map(Number) : []))

  inject('toggleSelectionQuery', (query, list) => {
    store.dispatch('search/selectedList', list || [])

    const selection = store.state.search.selectedList
    const selectedItems = selection.length
      ? { ...query, selectedItems: selection.join(',') }
      : { ...omit(query, 'selectedItems') }

    return app.router.push({
      query: {
        ...selectedItems
      }
    })
  })

  inject('updateQuery', (query, newQuery, updatePager = true) => {
    const defaultPager = updatePager ? { [EFilter.PAGE]: 1 } : {}
    const payload = {
      query: {
        ...query,
        ...newQuery,
        ...defaultPager
      }
    }

    return process.server ? redirect(payload) : app.router.push(payload)
  })

  inject('cleanQuery', (query, clean) => {
    const payload = {
      query: {
        ...omit(query, clean),
        [EFilter.PAGE]: 1
      }
    }

    return process.server ? redirect(payload) : app.router.push(payload)
  })

  inject('resetPage', (query) => {
    const payload = {
      query: {
        ...query,
        [EFilter.PAGE]: 1
      }
    }

    return process.server ? redirect(payload) : app.router.push(payload)
  })

  inject('getHistoryUrl', (route) => {
    switch (route.name) {
      case EHistoryPage.TALENT_ID: {
        if (Object.keys(store.state.leaveTalent).length) {
          return app.router.push(store.state.leaveTalent.fullPath)
        } else {
          return app.router.push(app.$link.searchTalents())
        }
      }
      case EHistoryPage.PROJECT_ID_SUMMARY:
      case EHistoryPage.PROJECT_ID_STATUS:
      case EHistoryPage.PROJECT_ID_STATUS_LABELS_LABELID: {
        if (Object.keys(store.state.leaveProject).length) {
          return app.router.push(store.state.leaveProject.fullPath)
        } else {
          return app.router.push(app.$link.searchProjects())
        }
      }
      case EHistoryPage.POOL:
      case EHistoryPage.POOL_TALENTS:
      case EHistoryPage.POOL_PROJECTS: {
        if (Object.keys(store.state.leavePool).length) {
          return app.router.push(store.state.leavePool.fullPath)
        } else {
          return app.router.push(app.$link.pools())
        }
      }
      case EHistoryPage.APPLICATION_APP_ID: {
        if (Object.keys(store.state.leaveApp).length) {
          return app.router.push(store.state.leaveApp.fullPath)
        } else if (store.state.leaveEmpty) {
          return app.router.go(-1)
        } else {
          return app.router.push(app.$link.dashboard())
        }
      }
      case EHistoryPage.PROJECT_CREATE: {
        if (store.state.leaveEmpty) {
          return app.router.go(-1)
        } else {
          return app.router.push(app.$link.dashboard())
        }
      }
      case EHistoryPage.MANAGE_PROJECT_ID_EDIT:
      case EHistoryPage.MANAGE_PROJECT_ID_UNPUBLISHED: {
        if (store.state.leaveEmpty) {
          return app.router.go(-1)
        } else {
          return app.router.push(app.$link.projectStatus(route.params.id, EPipelineStage.ACTIVE))
        }
      }
      case EHistoryPage.APPLICATION_APP_ID_W_STATUS:
        if (Object.keys(store.state.leaveApp).length) {
          return app.router.push(store.state.leaveApp.fullPath)
        } else {
          return app.router.push(app.$link.projectStatus(route.params.id, route.params.status))
        }
      case EHistoryPage.APPLICATION_APP_ID_W_LABEL:
        if (Object.keys(store.state.leaveApp).length) {
          return app.router.push(store.state.leaveApp.fullPath)
        } else {
          // prettier-ignore
          return app.router.push(app.$link.projectLabel(route.params.id, route.params.status, route.params.labelId))
        }
      default:
        return app.router.push(app.$link.dashboard())
    }
  })

  function resolveRoute(routeObject) {
    const resolved = app.router.resolve(routeObject)
    return resolved ? resolved.route.redirectedFrom || resolved.route.fullPath : ''
  }

  inject('link', {
    analytics: () => resolveRoute({ name: 'analytics' }),

    application: (appId, { projectId: id, status, labelId, query } = {}) => {
      if (isNil(id) || isNil(status)) {
        return resolveRoute({
          name: 'application-appId',
          params: { appId },
          query
        })
      }

      if (isNil(labelId)) {
        return resolveRoute({
          name: 'project-id-status-application-appId',
          params: { id, status, appId },
          query
        })
      }

      return resolveRoute({
        name: 'project-id-status-labels-labelId-application-appId',
        params: { id, status, labelId, appId },
        query
      })
    },

    createProject: () => resolveRoute({ name: 'project-create' }),

    dashboard: (tab = 'projects') =>
      resolveRoute({
        name: tab === 'pools' ? 'dashboard-pools' : 'dashboard-projects'
      }),

    forgotPassword: () => resolveRoute({ name: 'auth-forgot-password' }),

    index: () => resolveRoute({ name: 'index' }),

    login: () => resolveRoute({ name: 'auth-login' }),

    pool: (id) =>
      resolveRoute({
        name: 'pool-id',
        params: { id }
      }),

    poolProjects: (id) =>
      resolveRoute({
        name: 'pool-id-projects',
        params: { id }
      }),

    poolTalents: (id) =>
      resolveRoute({
        name: 'pool-id-talents',
        params: { id }
      }),

    pools: () => resolveRoute({ name: 'pools' }),

    // project management pages url's
    projectEdit: (id) =>
      resolveRoute({
        name: 'manage-project-id-edit',
        params: { id }
      }),

    projectUnpublished: (id) =>
      resolveRoute({
        name: 'manage-project-id-unpublished',
        params: { id }
      }),

    projectSummary: (id) =>
      resolveRoute({
        name: 'project-id-summary',
        params: { id }
      }),

    // project status/labels pages url's
    projectStatus: (id, status, query) =>
      resolveRoute({
        name: 'project-id-status',
        params: { id, status },
        query: { ...query }
      }),

    projectLabel: (id, status, labelId, query) =>
      resolveRoute({
        name: 'project-id-status-labels-labelId',
        params: { id, status, labelId },
        query: { ...query }
      }),

    searchTalents: (query) =>
      resolveRoute({
        name: 'search-talents',
        query
      }),

    searchTalentsExternal: (provider) =>
      resolveRoute({
        name: 'search-external-talents-provider',
        params: { provider }
      }),

    searchProjects: (query) =>
      resolveRoute({
        name: 'search-projects',
        query
      }),

    settings: () => resolveRoute({ name: 'settings' }),

    talent: (id) =>
      resolveRoute({
        name: 'talent-id',
        params: { id }
      }),

    talentExternal: (provider, id) =>
      resolveRoute({
        name: 'search-external-talents-provider-id',
        params: { provider, id }
      })
  })
}
