export default (axios) => ({
  create(body) {
    return axios.post('/api/project', body, { 'Content-Type': 'multipart/form-data' })
  },

  get(jobHandleId) {
    return axios.get(`/api/project/${jobHandleId}`)
  },

  update(jobHandleId, body) {
    return axios.put(`/api/project/${jobHandleId}`, body)
  },

  updateTimeline(id, body) {
    return axios.put(`/api/project/${id}/timelines`, body)
  },

  updatePositions(id, body) {
    return axios.put(`/api/project/${id}/positions`, body)
  },

  updatePools(id, body, config = {}) {
    return axios.put(`/api/project/${id}/poolsUpdate`, body, config)
  },

  getHistory(jobHandleId) {
    return axios.get(`/api/project/${jobHandleId}/history`)
  },

  getPipelineStages(jobHandleId) {
    return axios.get(`/api/project/${jobHandleId}/pipeline/stages`)
  },

  getFreelancerBulk(jobHandleId, query) {
    return axios.get(`/api/project/${jobHandleId}/freelancers`, {
      params: {
        ...query
      }
    })
  },

  getPoolsBulk(jobHandleId, query) {
    return axios.get(`/api/project/${jobHandleId}/pools`, {
      params: {
        ...query
      }
    })
  },

  getApplications(jobHandleId) {
    return axios.get(`/api/project/${jobHandleId}/applications`)
  },

  uploadAttachment(jobHandleId, binary) {
    const binaryData = new FormData()
    binaryData.append('resource', binary)

    return axios.post(`/api/project/${jobHandleId}/attachment`, binaryData)
  },

  deleteAttachment(jobHandleId, attachmentId) {
    return axios.delete(`/api/project/${jobHandleId}/attachment/${attachmentId}`)
  },

  updateAssignees(jobHandleId, body) {
    return axios.put(`/api/project/${jobHandleId}/assignmentsUpdate`, body)
  },

  publish(projectId) {
    return axios.put(`/api/project/publish/${projectId}`)
  },

  extract(binary) {
    const binaryData = new FormData()
    binaryData.append('resource', binary)

    return axios.post('/api/project/extract', binaryData)
  },

  getTemplatesList() {
    return axios.get('/api/project/template/listAll')
  },

  deleteTemplate(projectId) {
    return axios.delete(`/api/project/${projectId}/template`)
  },

  addToPoolBulk(body) {
    return axios.post('/api/pool/project/add', body)
  },

  switchStatus(body) {
    return axios.post('/api/project/candidatesavailable', body)
  },
  closeOrReopen(body) {
    return axios.post('/api/project/closeOrReopen', body)
  },
  switchStatusBulk(body) {
    return axios.post('/api/project/candidatesavailableBulk', body)
  },

  getCandidateAvailability(jobHandleId) {
    return axios.post(`/api/project/${jobHandleId}/candidatesavailable`)
  },

  updateRateRange(jobHandleId, body) {
    return axios.post(`/api/project/${jobHandleId}/updateRateRange`, body)
  },

  updateClosingReason(jobHandleId, body) {
    return axios.put(`/api/project/${jobHandleId}/reasonforclosing`, body)
  },

  updateDeadline(jobHandleId, body) {
    return axios.post(`/api/project/${jobHandleId}/updatePublicDeadline`, body)
  },

  getInvitations(jobHandleId, query) {
    return axios.get(`/api/project/${jobHandleId}/invitation`, {
      params: {
        ...query
      }
    })
  },

  getOpenInvitations(jobHandleId, query) {
    return axios.get(`/api/project/${jobHandleId}/invitation/open`, {
      params: {
        ...query
      }
    })
  },

  createInvitation(body) {
    return axios.post('/api/projectInvitation/add', body)
  },

  applyToProject(projectId, body) {
    return axios.post(`/api/project/${projectId}/apply`, body)
  },

  bulkDelete(body) {
    return axios.delete(`/api/projects`, { data: body })
  },

  getAppIdsQueue(body) {
    return axios.post(`/api/projectApplication/projectScroll`, body)
  },

  getAllDataHarborTalents(jobHandleId, query) {
    return axios.get(`/api/project/${jobHandleId}/dataHarbor/talents/matched`, {
      params: {
        ...query
      }
    })
  },
  inviteDataHarborTalent(jobHandleId, body) {
    return axios.post(`/api/project/${jobHandleId}/dataHarbor/talents/invite`, {
      matchingIds: body
    })
  },
  getDataHarborTalentCv(id) {
    return axios.get(`/api/dataHarborTalent/${id}/cv`, { responseType: 'arraybuffer' })
  }
})
