export const DEFAULT_CONTEXT = Object.freeze({
  id: 0,
  name: '',
  legalEntity: [],
  languages: [],
  talentDomainName: '',
  projectLogo: '',
  mspLogo: '',
  // below come from BE
  localLegalEntities: [],
  hasConsentEnabled: false,
  googleCalendarAppClientId: ''
})

export const FETCH_CONTEXT_FIELDS = [
  'id',
  'name',
  'legal_entity.id',
  'legal_entity.name',
  'languages',
  'talent_domain_name',
  'project_logo',
  'msp_logo'
]

export const EClientConfig = Object.freeze({
  CURRENCIES: 'cl__currencies',
  ENABLE_ANALYTICS_PAGE: 'cl__enable_analytics_page',
  ENABLE_COUNTRIES_OF_INTEREST: 'cl__countries_of_interest_enabled',
  ENABLE_EXTERNAL_APP_LINK: 'cl__enable_external_application_link',
  ENABLE_MANUAL_HIRE: 'cl__msp_manual_hire_enabled',
  ENABLE_PAQ: 'cl__enable_pre_application_questions',
  ENABLE_PROJECT_OWNERSHIP: 'cl__project_ownership',
  ENABLE_TEAM: 'cl__job_assignee_limit',
  ENABLE_WHATSAPP_CONTACT: 'cl__enable_whatsapp_contact',
  EXTERNAL_SEARCH: 'cl__external_search',
  MSP_APPLIES_TALENT: 'cl__msp_applies_talent_for_a_job',
  MSP_LOGIN: 'cl__msp_login',
  PREFERRED_SORTED_COUNTRIES: 'cl__preferred_sorted_countries',
  TASKS_SETTINGS: 'cl__tasks_settings'
})

export const FETCH_CLIENT_CONFIGS = Object.values(EClientConfig)

export function mapClientConfig(clientConfigs, key) {
  try {
    const rawConfig = clientConfigs.find(({ configKey }) => configKey === key)
    const rawValue = rawConfig?.configValue

    switch (key) {
      case EClientConfig.CURRENCIES:
        return rawValue ? Object.values(rawValue) : []
      case EClientConfig.ENABLE_ANALYTICS_PAGE:
        return rawValue?.value || false
      case EClientConfig.ENABLE_COUNTRIES_OF_INTEREST:
        return rawValue?.value || false
      case EClientConfig.ENABLE_EXTERNAL_APP_LINK:
        return rawValue?.value || false
      case EClientConfig.ENABLE_MANUAL_HIRE:
        return rawValue?.value || false
      case EClientConfig.ENABLE_PAQ:
        return rawValue?.value || false
      case EClientConfig.ENABLE_PROJECT_OWNERSHIP:
        return rawValue?.value || false
      case EClientConfig.ENABLE_TEAM:
        return rawValue?.value > 0
      case EClientConfig.ENABLE_WHATSAPP_CONTACT:
        return rawValue?.value || false
      case EClientConfig.EXTERNAL_SEARCH:
        return rawValue?.externalSet || []
      case EClientConfig.MSP_APPLIES_TALENT:
        return rawValue?.value || false
      case EClientConfig.MSP_LOGIN:
        return rawValue || {}
      case EClientConfig.PREFERRED_SORTED_COUNTRIES:
        return rawValue ? Object.values(rawValue) : []
      case EClientConfig.TASKS_SETTINGS:
        return rawValue
      default:
        console.error(`Uknown client config: ${key}`)
    }
  } catch (err) {
    console.error(`An error has been caught when parsing ${key} configuration`, err)
  }
}

export const ELeConfig = Object.freeze({
  ASSESSMENTS_ENABLED: 'le__assessment_test_active_application',
  BULK_REJECT_JOB_APPS: 'le__bulk_reject_job_applications_pending',
  JOB_FIELD_SETTINGS: 'le__job_field_settings',
  MULTIPLE_APPLICATIONS_ALLOWED: 'le__job_multiple_applications_allowed',
  VMS_TYPE: 'le__vms_type'
})

export const FETCH_LE_CONFIGS = Object.values(ELeConfig)

export function mapLeConfig(configs, key) {
  const rawValue = configs?.get(key)

  try {
    switch (key) {
      case ELeConfig.ASSESSMENTS_ENABLED:
        return rawValue?.value || false
      case ELeConfig.BULK_REJECT_JOB_APPS:
        return rawValue?.value || false
      case ELeConfig.JOB_FIELD_SETTINGS:
        return rawValue || {}
      case ELeConfig.MULTIPLE_APPLICATIONS_ALLOWED:
        return rawValue?.value || false
      case ELeConfig.VMS_TYPE:
        return rawValue || {}
      default:
        console.error(`Uknown legal entity config: ${key}`)
    }
  } catch (err) {
    console.error(`An error has been caught when parsing ${key} legal entity configuration`, err)
  }
}

export function parseRawLeConfigs(leConfigs) {
  const entityConfigMap = new Map()

  for (const { leName, configKey, configValue } of leConfigs) {
    if (!entityConfigMap.has(leName)) {
      entityConfigMap.set(leName, new Map())
    }

    entityConfigMap.get(leName).set(configKey, configValue)
  }

  return entityConfigMap
}
