export const ECurrency = Object.freeze({
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
  GBP: 'GBP',
  CNY: 'CNY',
  CAD: 'CAD',
  INR: 'INR',
  HKD: 'HKD',
  SGD: 'SGD',
  SEK: 'SEK'
})

export const EEmailTemplateKeys = Object.freeze({
  EMAIL_BY_MSP: 'template.motivation.talent.email_by_msp',
  EMAIL_TO_POOL: 'template.motivation.pool.email_by_msp',
  EMAIL_BY_MSP_APPLICATION: 'template.motivation.applicant.email_by_msp',
  JOB_CLOSING: 'template.motivation.job.closing',
  JOB_REJECTION: 'template.motivation.job.rejection',
  POOL_REJECTION: 'template.motivation.pool.rejection',
  TALENT_DELETION: 'template.motivation.talent.deletion',
  TALENT_DEACTIVATION: 'template.motivation.talent.deactivation'
})

export const EModalType = Object.freeze({
  DEACTIVATE: 'deactivate',
  DELETE: 'delete'
})

export const EFlowType = Object.freeze({
  INVITE_TO_PROJECT: 'INVITE_TO_PROJECT',
  APPLY_TO_PROJECT: 'APPLY_TO_PROJECT',
  ADD_TO_POOL: 'ADD_TO_POOL',
  SEND_EMAIL: 'SEND_EMAIL',
  INVITE_TALENT: 'INVITE_TALENT',
  ALLOCATE_MSP: 'ALLOCATE_MSP',
  REJECT_AS_MSP: 'REJECT_AS_MSP',
  SEND_TO_VMS: 'SEND_TO_VMS',
  RE_SEND_TO_VMS: 'RE_SEND_TO_VMS',
  RE_ACTIVATE: 'RE_ACTIVATE',
  CONFIRM_POOL_MEMBERSHIP: 'CONFIRM_POOL_MEMBERSHIP',
  HIRE: 'HIRE',
  UN_HIRED: 'UN_HIRED',
  DROP_LABEL: 'DROP_LABEL',
  CANCEL_SENDING_TO_VMS: 'CANCEL_SENDING_TO_VMS',
  INVITE_EXTERNAL: 'INVITE_EXTERNAL',
  CHANGE_PROJECT_STATUS: 'CHANGE_PROJECT_STATUS',
  PROJECT_DELETE: 'PROJECT_DELETE',
  INTERVIEW_NEW: 'INTERVIEW_NEW',
  INTERVIEW_SHOW: 'INTERVIEW_SHOW',
  INTERVIEW_CANCELED: 'INTERVIEW_CANCELED',
  OFFER_NEW: 'OFFER_NEW',
  OFFER_SHOW: 'OFFER_SHOW',
  OFFER_WITHDRAWN: 'OFFER_WITHDRAWN'
})

export const EHistoryPage = Object.freeze({
  DASHBOARD_POOLS: 'dashboard-pools',
  DASHBOARD_PROJECTS: 'dashboard-projects',
  SEARCH_TALENTS: 'search-talents',
  SEARCH_PROJECTS: 'search-projects',
  PROJECT_CREATE: 'project-create',
  MANAGE_PROJECT_ID_EDIT: 'manage-project-id-edit',
  MANAGE_PROJECT_ID_UNPUBLISHED: 'manage-project-id-unpublished',
  TALENT_ID: 'talent-id',
  PROJECT_ID_STATUS_LABELS_LABELID: 'project-id-status-labels-labelId',
  PROJECT_ID_STATUS: 'project-id-status',
  PROJECT_ID_SUMMARY: 'project-id-summary',
  APPLICATION_APP_ID: 'application-appId',
  APPLICATION_APP_ID_W_STATUS: 'project-id-status-application-appId',
  APPLICATION_APP_ID_W_LABEL: 'project-id-status-labels-labelId-application-appId',
  POOL: 'pool-id',
  POOL_TALENTS: 'pool-id-talents',
  POOL_PROJECTS: 'pool-id-projects'
})

export const ELocationType = Object.freeze({
  LOCALITY: 'LOCALITY',
  COUNTRY: 'COUNTRY',
  SUB_LOCALITY: 'SUB_LOCALITY'
})

export const EPeriods = Object.freeze({
  HR: 'HR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  PROJECT: 'PROJECT'
})

export const EPipelineStage = Object.freeze({
  DATA_HARBOUR: 'dataHarbour',
  SUGGESTED: 'suggested',
  INVITED: 'invited',
  ACTIVE: 'active',
  REJECTED_WITHDRAW: 'rejectedOrWithdrawn',
  SEND2VMS: 'send2vms',
  HIRED: 'hired',
  REJECTED: 'rejectedVms'
})

export const EResponsiveMenuEntryType = Object.freeze({
  ENTRY: 'ENTRY',
  SEPARATOR: 'SEPARATOR',
  SUBMENU: 'SUBMENU'
})

export const ESimpleRegex = Object.freeze({
  EMAIL: /[@]./,
  NUMBER: /^\d+$/
})

export const EGtmEvent = Object.freeze({
  HR_ACCEPT_POOL_APPLICATION: 'hrAcceptPoolApplication',
  HR_ADD_TALENT_TO_POOL: 'hrAddTalentToPool',
  HR_APPLY_TALENT_TO_PROJECT: 'hrApplyTalentToProject',
  HR_CHANGE_LANGUAGE: 'hrChangeLanguage',
  HR_CHANGE_PASSWORD: 'hrChangePassword',
  HR_CREATE_PROJECT: 'hrCreateProject',
  HR_EDIT_APPLICATION_QUESTIONS: 'hrEditsApplicationQuestions',
  HR_EDIT_GOOGLE_CALENDAR_CONNECTION: 'hrEditGoogleCalendarConnection',
  HR_EDIT_LE_PRE_APPLICATION_QUESTIONS: 'hrEditLePreApplicationQuestions',
  HR_EDIT_PROJECT: 'hrEditProject',
  HR_EDIT_TAG: 'hrEditTag',
  HR_EDIT_TALENT_PROFILE: 'hrEditTalentProfile',
  HR_FORGOT_PASSWORD: 'hrForgotPassword',
  HR_INVITE_TALENT_FROM_MONSTER: 'hrInviteTalentFromMonster',
  HR_INVITE_TALENT_TO_PROJECT: 'hrInviteTalentToProject',
  HR_LOGIN_ERROR: 'hrLoginError',
  HR_MOVED_THROUGH_PIPELINE: 'hrMovedThroughPipeline',
  HR_PAUSE_PROJECT: 'hrPauseProject',
  HR_REJECT_POOL_APPLICATION: 'hrRejectPoolApplication',
  HR_REJECT_POOL_PROJECT_APPLICATION: 'hrRejectPoolProjectApplication',
  HR_REJECT_PROJECT_APPLICATION: 'hrRejectProjectApplication',
  HR_REOPEN_PROJECT: 'hrReopenProject',
  HR_RESET_PASSWORD: 'hrResetPassword',
  HR_SEND_EMAIL_TO_PROJECT_APPLICATION: 'hrSendEmailToProjectApplication',
  HR_SEND_EMAIL_TO_TALENT: 'hrSendEmailToTalent',
  HR_SET_CLOSING_REASON: 'hrSetClosingReason',
  MSP_DATA: 'mspData',
  MSP_LOGGED_DATA: 'mspLoggedData',
  HR_CLOSE_PROJECT: 'hrCloseProject'
})

export const ERouter = Object.freeze({
  APPLICATION: 'application-appId',
  APPLICATION_IN_LABEL: 'project-id-status-labels-labelId-application-appId',
  APPLICATION_IN_STATUS: 'project-id-status-application-appId',
  EXTERNAL_TALENTS_PROFILE: 'search-external-talents-provider-id',
  POOL: 'pool-id',
  POOL_PROJECTS: 'pool-id-projects',
  POOL_TALENTS: 'pool-id-talents',
  PROJECT_EDIT: 'manage-project-id-edit',
  PROJECT_LABEL: 'project-id-status-labels-labelId',
  PROJECT_STATUS: 'project-id-status',
  PROJECT_SUMMARY: 'project-id-summary',
  PROJECT_UNPUBLISHED: 'manage-project-id-unpublished',
  SEARCH_EXTERNAL_TALENTS: 'search-external-talents-provider',
  SEARCH_PROJECTS: 'search-projects',
  SEARCH_TALENTS: 'search-talents',
  TALENT: 'talent-id'
})

export const EDateType = Object.freeze({
  SHORT: 'SHORT',
  LONG: 'LONG',
  YEAR: 'YEAR',
  LONG_WITH_TIME: 'LONG_WITH_TIME'
})

export const EAuthType = Object.freeze({
  AUTH0: 'AUTH0',
  PASSWORD: 'PWD'
})

export const EPermission = Object.freeze({
  // client-level permissions
  MANAGE_TAGS: 'a',
  ASSIGN_TAGS: 'b',
  CALENDAR: 'c',
  FREELANCER_HISTORY: 'd',
  FREELANCER_ACTION_LOG: 'e',
  FREELANCER_MANAGE: 'f',
  POOL_MANAGE: 'g',
  DASHBOARD: 'h',
  TEAM_MANAGEMENT: 'i',
  IGNORE_OWNERSHIP_RESTRICTIONS: 'o',
  // legal entity-level permissions
  APPLICATION_EDIT_CONTENT: 'k',
  JOB_TEAM_MANAGEMENT: 'l',
  APPLICATION_VIEW: 'm',
  APPLICATION_MANAGE: 'n',
  JOB_MANAGE_CREATE: 'o',
  JOB_MANAGE_READ: 'p',
  JOB_MANAGE_UPDATE: 'q',
  JOB_MANAGE_PUBLISH: 'r',
  JOB_MANAGE_DELETE: 's',
  JOB_MANAGE_POOL: 't'
})
