import { EInternalTypes, EFlowParseJson, defineValidation } from '@twago/flows'
import { EDateType, ETalentHistoryEntryType } from '@/helpers/enums'

export const transformLog = (log) => ({ type: ETalentHistoryEntryType.LOG, ...log })

export const transformPool = (pool, talent) => ({
  created: pool.membershipCreated,
  freelancer: {
    id: talent.id,
    email: talent.email,
    firstName: talent.firstName,
    lastName: talent.lastName
  },
  type: ETalentHistoryEntryType.POOL,
  ...pool
})

export const transformProject = (project) => ({
  type: ETalentHistoryEntryType.PROJECT,
  ...project
})

export const isUnavailableToday = (ctx, unavailabilities) =>
  Array.isArray(unavailabilities) &&
  unavailabilities.some(({ notAvailableFrom, notAvailableUntil }) => {
    const from = ctx.$moment(notAvailableFrom)
    const to = ctx.$moment(notAvailableUntil)
    const now = ctx.$moment()

    return now.isBetween(from, to)
  })

export function formatHistoryRecordDate(ctx, value, fieldName) {
  const field = ctx.config.form?.find((e) => e.name === fieldName)
  let format

  switch (field?.option) {
    case EInternalTypes.DATE_YEAR_ONLY:
      format = EDateType.YEAR
      break
    case EInternalTypes.DATE_MONTH:
      format = EDateType.SHORT
      break
    // case EInternalTypes.DATE_YEAR:
    // case EInternalTypes.DATE_FULL:
    default:
      format = EDateType.LONG
      break
  }

  return ctx.$displayDate({ value, format })
}

export function transformCertificate(ctx, entry) {
  return {
    title: entry.title,
    subtitle2: formatHistoryRecordDate(ctx, entry.startDate, EFlowParseJson.FIELD_START_DATE),
    description: entry.description,
    resource: entry.history_attachment
      ? {
          uuid: entry.history_attachment,
          name: ctx.fileNames[entry.history_attachment]
        }
      : undefined
  }
}

export function transformEducation(ctx, entry) {
  const from = formatHistoryRecordDate(ctx, entry.startDate, EFlowParseJson.FIELD_START_DATE)
  const to = entry.ongoing
    ? ctx.$t('msp.v2.date.present')
    : formatHistoryRecordDate(ctx, entry.endDate, EFlowParseJson.FIELD_END_DATE)
  const dates = entry.endDate || entry.ongoing ? `${from} - ${to}` : from
  const resource = entry.uuid
    ? {
        uuid: entry.history_attachment,
        name: ctx.fileNames[entry.history_attachment]
      }
    : undefined

  return {
    title: entry.description,
    subtitle: entry.title,
    subtitle2: dates,
    resource
  }
}

export function transformJob(ctx, entry) {
  const from = formatHistoryRecordDate(ctx, entry.startDate, EFlowParseJson.FIELD_START_DATE)
  const to = entry.ongoing
    ? ctx.$t('msp.v2.date.present')
    : formatHistoryRecordDate(ctx, entry.endDate, EFlowParseJson.FIELD_END_DATE)
  const dates = entry.endDate || entry.ongoing ? `${from} - ${to}` : from
  const resource = entry.history_attachment
    ? {
        uuid: entry.history_attachment,
        name: ctx.fileNames[entry.history_attachment]
      }
    : undefined

  return {
    title: entry.title,
    subtitle: entry.organization,
    subtitle2: dates,
    description: entry.description,
    resource
  }
}

export function validateRepeaterForm(config, ctx) {
  if (!config.form?.length) return
  if (!config.value?.length) return

  const { form, value: values } = config
  const validators = new Map()
  const invalidElements = new Map()

  for (const config of form) {
    validators.set(config.name, defineValidation(config, ctx.$i18n))
  }

  for (const idx in values) {
    const value = values[idx]

    for (const field in value) {
      const rules = validators.get(field)

      for (const rule of rules) {
        const result = rule(value[field])

        // may be a string, then it fails
        if (result !== true) {
          invalidElements.set(idx, value)
          break
        }
      }
    }
  }

  return invalidElements
}
