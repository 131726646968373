export const EActionLogType = Object.freeze({
  CALL: 'cl',
  EMAIL: 'em',
  MEETING: 'mt',
  NOTE: 'nt'
})

export const ECvHistoryType = Object.freeze({
  CERTIFICATE: 'c_h',
  EDUCATION: 'e_h',
  WORK_EXPERIENCE: 'j_h'
})

export const ECvLabelType = Object.freeze({
  SKILL: 'skill',
  INDUSTRY: 'ind',
  TECHNOLOGY: 'soft'
})

export const ELanguageProficiency = Object.freeze({
  BEGINNER: 0,
  INTERMEDIATE: 1,
  FLUENT: 2,
  NATIVE: 3
})

export const EManageQuestion = Object.freeze({
  EDIT: 'EDIT',
  CREATE: 'CREATE'
})

export const ETalentCardType = Object.freeze({
  SEARCH: 'SEARCH',
  PROJECT: 'PROJECT',
  MINI: 'MINI'
})

export const EWorkPreference = Object.freeze({
  HYBRID: 'hd',
  ON_SITE: 'os',
  REMOTE: 'rt'
})

export const EQuestionType = Object.freeze({
  DEFAULT: 'default',
  JOB_SPECIFIC: 'jobSpecific'
})

export const ETalentEntryModalType = Object.freeze({
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  PATCH: 'PATCH'
})
