import { render, staticRenderFns } from "./QuestionElement.vue?vue&type=template&id=3934dcc4&"
import script from "./QuestionElement.vue?vue&type=script&lang=js&"
export * from "./QuestionElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppQuestionElement: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/App/QuestionElement.vue').default})
